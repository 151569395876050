body > iframe[style*='2147483647']{
  display: none;
}

.content {
  min-height: 100vh;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  font-family: 'Montserrat', sans-serif;
}
.settingsBlock {
  min-height: 500px;
}

.disable-clac {
  display: none;
}

.ant-list-item-action {
  margin-left: 0;
}

.activeProductCardAction {
  color: '@primaryColor[6]';
}

#CartDrawer .ant-drawer-body {
  padding: 0;
}

#paymentMethods .ant-select-selection-selected-value {
  width: 100%;
}
.checkboxPaymentMethod .ant-form-item-control {
  line-height: 0px;
}
.shippingMode .ant-form-item-control {
  line-height: 0px;
}

.ant-spin-spinning {
  display: flex;
  justify-content: center;
  align-items: center;
}

div#paymentsLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.DeliveryView tbody.ant-table-tbody {
  background-color: #ffffff;
}

.DeliveryView span.ant-descriptions-item {
  padding-bottom: 10px;
}

.DeliveryView span.ant-descriptions-item-label.ant-descriptions-item-colon {
  font-weight: 600;
}

.SubscriptionTable .ant-drawer-content-wrapper {
  width: 50% !important;
}

ul.ant-pagination.ant-table-pagination {
  margin-left: 5px;
  float: left;
}

.recharts-wrapper {
  margin: 0 auto;
}

/* NOTE The Products page table is only used for its header  */
.ProductsList .ant-list-header {
  border-bottom: none;
}

.ProductsList .ant-list-header {
  padding-top: 0;
}
.productCard .ant-card-actions > li {
  margin: 6px 0;
}
/* NOTE space out action button on product cards */
.productCardItem li {
  width: 100%;
}

.productCard .ant-card-body {
  padding: 0px;
}

.catalog-card .ant-card-body {
  padding: 0px;
}

.catalog-card:hover img {
  transform: scale(1.1);
}

.catalog-card-cover {
  overflow: hidden;
}

.catalog-card-cover img {
  transition: transform 0.5s ease;
}

.catalog-card-title {
  font-weight: bold;
  height: 42px;
  white-space: pre-line;
  overflow: hidden;
}

.catalog-card-price {
  color: grey;
  margin-top: 2px;
  white-space: pre-line;
}

.header-banner {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  font-size: 12px;
  height: 48px;
  max-width: 485px;
  display: flex;
  align-items: center;
}

.ui-helper-hidden-accessible {
  display: none !important;
}

div#widget-container {
  height: auto !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}
div#colissimo-container .row {
  margin: 0 !important;
}
div#colissimo-container .colissimo_widget_w363 {
  width: 100% !important;
  margin: 5px 0 !important;
}
input#colissimo_widget_CodePostal {
  width: 25% !important;
  margin: 5px 0 !important;
}
input#colissimo_widget_Ville {
  width: 40% !important;
  margin: 5px 0 5px 10px !important;
}
select#colissimo_widget_listePays {
  width: 20% !important;
  margin: 5px 0 5px 10px !important;
}
div#colissimo-container .colissimo_widget_text-lang-active {
  color: #0000ff !important;
}
div#colissimo-container .colissimo_widget_input-frame {
  color: #0000ff !important;
}
button#colissimo_widget_rechercher-submit {
  background: #0000ff !important;
  margin: 5px 0 !important;
  line-height: 16px !important;
  width: 100% !important;
}

div#colissimo_widget_onYourMapPudo {
  left: 0 !important;
  width: 100% !important;
}

h3#colissimo_widget_titrePopup1 {
  color: #0000ff !important;
}

button#colissimo_widget_submit-valider-point-retrait1 {
  background: #0000ff !important;
  width: auto !important;
  margin: 10px 0 !important;
  line-height: 16px !important;
  float: none !important;
  padding: 0 5px !important;
}
div#colissimo_widget_pictosInfosBulles1 {
  display: none;
}
div#colissimo_widget_zoneTableauHoraires1 {
  margin-left: 0 !important;
}
div#colissimo_widget_zoneTableauHoraires1 h1.couleur2 {
  color: #0000ff !important;
}
.colissimo_widget_closeInfobulle {
  filter: grayscale(1);
}
div#colissimo_widget_zoneTableauHoraires1 table {
  width: 96%;
}
.colissimo_widget_body1Infobulle {
  padding: 0 !important;
}
.styled-pickup-modal .content {
  width: 50% !important;
  min-height: unset !important;
  position: relative !important;
}
.styled-pickup-modal .closer {
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
}

#memberCreation .ant-row.ant-form-item {
  margin-bottom: 5px;
}

#memberCreation .ant-col.ant-form-item-label {
  line-height: 20px;
}

span.ant-upload-picture-card-wrapper {
  width: auto;
  display: flex;
}

.productRecap .ant-timeline-item-content {
  min-height: 0px;
}

.ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0px;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 0px;
  margin-top: 0px;
}
.upload-list-inline img.ant-upload-list-item-image {
  width: 40px;
  height: 40px;
}
.upload-list-inline .ant-upload-list-item-info > span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-list-inline a.ant-upload-list-item-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bpWorkshop .ant-upload-list-picture-card-container {
  width: 250px;
  height: 250px;
}
.bpWorkshop
  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
  width: 250px;
  height: 250px;
}
.bpWorkshop .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 250px;
  height: 250px;
}

.rect-img-container {
  position: relative;
}

.rect-img-container::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.rect-img-container-no {
  display: none;
}
.react-multi-carousel-dot-list {
  position: relative;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.ant-comment-inner {
  padding: 0;
}

#userHeaderHover:hover {
  opacity: 0.7;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  width: 220px !important;
  min-width: 220px !important;
  max-width: 220px !important;
}

.stepsCampaignModal
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title
  > h3 {
  color: #0000ff !important;
}

.stepsCampaignModal
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title
  > h3 {
  color: #0000ff !important;
}

.stepsCampaignModal {
  padding: 0 20px;
}
body {
  font-family: 'Montserrat', sans-serif;
}

.productCampaign .ant-card-body {
  padding: 10px 8px !important;
}

i.anticon.anticon-plus.productCampaign {
  font-size: 16px;
}

i.anticon.anticon-plus.productCampaign:hover {
  color: #0000ff;
}
.ant-page-header.ant-page-header-ghost {
  padding: 5px 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

h1 {
  font-size: 25px;
}

.campaignList .ant-card-body {
  padding: 0px;
}

span.ant-badge-status-text {
  font-weight: 600;
  color: #000000;
}

.ql-align-left {
  text-align: left;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}

#loadingSetLanguageProductEditor {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  opacity: 0;
}

#loadingSetLanguageProductEditor.loading {
  opacity: 1;
  z-index: 100;
}

#campaignSendTestEmail .ant-btn-primary {
  background-color: #fff;
  border-color: #d5d5d5;
  color: rgba(0, 0, 0, 0.65);
}
#campaignSendTestEmail .ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: #2930ff;
  color: #2930ff;
}

.templateVariables:hover,
.templateVariables:focus {
  border-color: #2930ff;
  color: #2930ff;
}

.ant-modal.modalMagicLinkCreation > .ant-modal-content > .ant-modal-body {
  padding: 0;
}

.ant-page-header-heading {
  width: 100%;
  overflow: initial !important;
}
.ant-page-header-heading-title {
  font-size: 30px !important;
}

.searchCampaign input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8197AD
}

.statusCampaign .ant-select-selection__placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8197AD
}

.apiKey span.ant-alert-message {
  font-weight: bold;
}

#copied {
  border-color: #22C55E !important;
  box-shadow: 0 0 0 2px rgb(34,197,94, 0.2);
}

#warning-icon-danger {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF1F0;
  font-size: 20px;
  border-radius: 99999%;
}

.table-action {
  color: #0000FF;
  font-size: 14px;
  margin: 0 4px;
}

.antd-collapse-no-panel {
  background: white;
  border-bottom: 0 !important;
}

.ant-collapse > .ant-collapse-item.antd-collapse-no-panel > .ant-collapse-header {
  padding: 0;
}

.ant-collapse-borderless > .ant-collapse-item.antd-collapse-no-panel > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 0 10px 0;
}

.searchAdminInput input {
  border: none;
}

.searchAdminInput input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8197AD;
  font-family: 'Montserrat';
  font-style: normal;
}

.adminTable table .ant-table-header-column {
  width: 100%;
}

.adminTable table .ant-table-column-sorters {
  width: 100%;
}

.adminTabHeader {
  font-weight: 600;
  font-size: 14px;
  font-family: 'Montserrat';
  line-height: 22px;
  color: #4E647A;
}

.ant-modal.budget-modal > .ant-modal-content {
  border-radius: 16px;
}

.ant-modal.budget-modal > .ant-modal-content > .ant-modal-body {
  padding: 0;
}

.modalMagicLinkSend > .ant-modal-content {
  border-radius: 16px;
  padding: 24px 24px 16px 24px;
}

.modalMagicLinkSend > .ant-modal-content > .ant-modal-body {
  text-align: center;
  padding: 0;
  padding-bottom: 48px;
}

.modalMagicLinkSend > .ant-modal-content > .ant-modal-footer {
  padding: 0;
  padding-top: 24px;
}

.ant-pagination-item-active {
  border-color: #E6E6FF;
  color: #0000FF;
}

.ant-pagination-item:focus {
  border-color: #E6E6FF;
}

.ant-pagination-next a {
  color: #0000FF;
}

.ant-pagination-prev a {
  color: #0000FF;
}

ul.ant-pagination.ant-table-pagination {
  float: right;
}

.ant-pagination-item {
  border: 1px solid #E6E9F0;
}

.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

.twoLinesEllipsis {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  line-height: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.square-container {
  position: relative;
}

.square-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square-container img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hoverableLink {
  cursor: pointer;
}

.hoverableLink:hover {
  color: #0000FF;
}

.autoComplete {
  width: 100%;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #E0E5EA;
  padding: 4px 11px;
  color: #1D262E;
}

.autoComplete::placeholder {
  color: #8197AD;
}

.autoComplete:hover {
  border: 1px solid #0000FF;
}
